import "../scss/main.scss";

import "./mobile-menu.js";
import { initKnowledgeBase } from "./knowledge-base.js";

window.initKnowledgeBase = initKnowledgeBase;

document.querySelectorAll('.article').forEach(article => {
  const link = article.querySelector('.article__read-more');
  if (link) {
    article.style.cursor = 'pointer';
    article.addEventListener('click', (e) => {
      if (!e.target.classList.contains('article__read-more')) {
        link.click();
      }
    });
  }
});
